<template>
  <t-modal
    :name="`editVehicleInfo-${primaryKey}`"
    :variant="`confirmation`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    :escToClose="true"
    :clickToClose="true"
  >
    <section class="px-4 pt-4 text-2xl font-extrabold text-center">
      {{
        $t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.modals.editVehicle.headline'
        )
      }}
    </section>

    <div class="px-5 pt-5">
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(handleUpdate())"
          enctype="multipart/form-data"
        >
          <AppInput
            v-if="singleUpdateKey === 'unlock_count'"
            v-model="unlock_count"
            rules="required"
            :label="
              $t(
                'components.vehicleDetailsManagement.diveIntoTab.profile.modals.editVehicle.title.unlockCount'
              )
            "
            type="text"
            :name="
              $t(
                'components.vehicleDetailsManagement.diveIntoTab.profile.modals.editVehicle.title.unlockCount'
              )
            "
          />
          <button type="submit" ref="editVehicleSubmitButtonRef" class="hidden">
            Save
          </button>
        </form>
      </ValidationObserver>
    </div>

    <section
      class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
    >
      <AppButton
        :variant="`secondary`"
        :class="`w-1/2`"
        @click="closeEditRiderModal()"
      >
        {{ $t('components.stepNavigation.cancel') }}
      </AppButton>

      <AppButton
        :variant="`primary`"
        :class="`w-1/2`"
        :isLoading="isLoading"
        @click="$refs.editVehicleSubmitButtonRef.click()"
      >
        {{ $t('components.stepNavigation.update') }}
      </AppButton>
    </section>
  </t-modal>
</template>

<script>
import { useEndpoints } from '@/composables'
export default {
  name: 'EditVehicleInfo',

  props: {
    primaryKey: {
      type: [String, Number],
      required: true,
    },
    profileData: {
      type: Object,
      required: true,
    },
    singleUpdateKey: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    isLoading: false,
    unlock_count: '',
  }),
  watch: {
    profileData: {
      immediate: true,
      deep: false,
      handler(data) {
        if (data) {
          this.unlock_count = data.lock.unlock_count - 2000
        }
      },
    },
    singleUpdateKey: {
      immediate: true,
      deep: true,
      handler(data) {
        console.log('single-edit', data)
      },
    },
  },

  methods: {
    handleUpdate() {
      const url = useEndpoints.lock.update(this.profileData.lock.id)
      const data = new FormData()
      if (this.singleUpdateKey === 'unlock_count') {
        if (isNaN(parseInt(this.unlock_count))) return
        this.isLoading = true
        data.append('unlock_count', parseInt(this.unlock_count) + 2000)
      }

      this.$http
        .patch(url, data)
        .then((res) => {
          console.log('vehicle-success', res)
          this.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: 'Success',
              text: res.data.data,
            },
            3000
          )
          this.$modal.hide(`editVehicleInfo-${this.primaryKey}`)
          this.unlock_count = ''
          this.$emit('refresh')
        })
        .catch((err) => {
          console.log('vehicle-err', { err })
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: 'Error occurred!',
            text: err.response.data.message,
          })
        })
        .finally(() => (this.isLoading = false))
    },
    closeEditRiderModal() {
      this.unlock_count = ''
      this.$modal.hide(`editVehicleInfo-${this.primaryKey}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
</style>
